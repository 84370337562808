<template>
	<div
		class="modal fade"
		id="assignBusinessAccountManagerModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="assignBusinessAccountManagerLabel"
		aria-hidden="true"
		>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="assignBusinessAccountManagerLabel">
						Assign Account Manager to Business
					</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div
						class="alert alert-danger alert-dismissible fade show"
						role="alert"
						v-if="errorMessage"
						>
						{{ errorMessage }}
					</div>
					<form @submit.prevent="assignBusinessAccountManager">
						<div class="row">
							<div class="col-12">
								<label class="form-label">Select Staff</label>
								<v-select
									v-model="form.selectedStaff"
									class="form-group"
									:options="computedStaff"
									label="fname"
									required
									placeholder="Select a staff to assign"
									>
									<template v-slot:option="singleStaff">
										{{ singleStaff.fname }} {{ singleStaff.lname }}
									</template>
									<template v-slot:selected-option="singleStaff">
										{{ singleStaff.fname }} {{ singleStaff.lname }}
									</template>
								</v-select>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal"
						ref="closeModalButton"
						@click="close"
						>
						Close
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="assignBusinessAccountManager"
						:disabled="processing || !formValid"
						>
						{{ processing ? 'Assigning staff...' : 'Assign' }}
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#assignBusinessAccountManagerModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    driver: Object,
    accountManagers: Array,
    companyId: [Number, String]
  },
  mixins: [ModalMixin],
  data () {
    return {
      form: {
        selectedStaff: null
      },
      processing: false,
      errorMessage: '',
      localStaff: []
    }
  },
  created () {
    if (this.staff) {
      this.localStaff = this.staff
    } else {
      this.fetchStaff()
    }
  },
  computed: {
    formValid () {
      return this.form.selectedStaff
    },
    computedStaff () {
      return this.staff || this.localStaff
    }
  },
  methods: {
    fetchStaff () {
      this.axios.get('/v1/staff').then((res) => {
        this.localStaff = res.data
      })
    },
    assignBusinessAccountManager () {
      if (!this.formValid) {
        return
      }

      this.processing = true

      this.errorMessage = ''

      this.axios
        .post(`v1/corporates/${this.companyId}/managers`, {
          staffId: this.form.selectedStaff.id
        })
        .then(() => {
          this.close()
          this.form.selectedStaff = null
          this.$emit('businessAccountManager-assigned')

          this.$swal({
            icon: 'success',
            title: 'Staff Assigned',
            text: 'Staff has been assigned successfully',
            showCloseButton: true
          })
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
